import Vue from 'vue'
import $ from 'jquery'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   {
      path: '/paginaInicial/:idTab',
      name: 'Página_inicial',
      component: () => import('../views/PaginaInicial.vue'),
      meta: {'icon': 'bx bx-home-circle'}
   },
   {
      path: '/contas/:idTab',
      name: 'Contas',
      component: () => import('../views/pages/contas/Contas.vue'),
      meta: {'icon': 'bx bx-sitemap'}
   },
   {
      path: '/closers/:idTab',
      name: 'Closers',
      component: () => import('../views/pages/closers/Closers.vue'),
      meta: {'icon': 'bx bx-briefcase'}
   },
   {
      path: '/usuarios/:idTab',
      name: 'Usuários',
      component: () => import('../views/pages/usuarios/Usuarios.vue'),
      meta: {'icon': 'bx bx-user'}
   },
   {
      path: '/info/:idTab',
      props: route => ({ query: route.query }),
      name: 'Conta',
      component: () => import('../views/pages/contas/Info.vue'),
      meta: {'icon': 'bx bx-sitemap'}
   },
]

const router = new VueRouter({
   mode: 'abstract',
   base: process.env.BASE_URL,
   routes
})

/* eslint-disable-next-line no-unused-vars */
router.beforeResolve((to, from, next) => {
   let inititalData = null;

   if (to.matched.length > 0 && to.matched[0].components.default.data) {
      inititalData = to.matched[0].components.default.data()
   }

   store.dispatch('addTabData', {'id': to.params.idTab, 'nome': to.name, 'path': to.path, 'query': to.query, 'icon': to.meta.icon, 'data': inititalData})

   $("#app > .wrapper").removeClass("toggled");
   $(".mm-active a").click()
   next()
})

export default router
