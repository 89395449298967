<template>
	<div class="wrapper">
		<div class="section-authentication-signin d-flex align-items-center justify-content-center my-0">
			<div class="container-fluid">
				<div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
					<div class="col mx-auto">
						<div class="card">
							<div class="card-body">
								<div class="border p-4 rounded">
									<div class="text-center">
                              <div class="mb-4 text-center">
                                 <img src="@/assets/images/logo-img2.png" class="logo-login" width="180" alt="" />
                              </div>
                              <h6 class="mb-3 font-18">Faça login para continuar</h6>
                           </div>
									<div class="form-body">
										<form class="row g-3">
											<div class="col-12">
                                    <label class="mb-1"><i class="far fa-envelope me-2 font-12"></i>Email</label>
                                    <input type="text" class="form-control font-14" placeholder="Digite seu email" v-model="usuario" @keyup.enter="verificarLogin">
                                 </div>
                                 <div class="col-12">
                                    <label class="mb-1"><i class="far fa-lock me-2 font-12"></i>Senha</label>
                                    <input type="password" class="form-control font-14" placeholder="Digite sua senha" v-model="senha" @keyup.enter="verificarLogin">
                                 </div>
											<div class="col-12">
                                    <div class="form-check form-switch w-max-content mx-auto">
                                       <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="manterConectado">
                                       <label class="form-check-label" for="flexSwitchCheckChecked">Mantenha-me conectado</label>
                                    </div>
                                 </div>
											<div class="col-12">
												<div class="d-grid">
													<button type="button" class="btn btn-primary font-14 d-flex justify-content-center align-items-center" @click="verificarLogin">
                                          <i class="far fa-sign-in-alt font-14 d-block me-2"></i>Entrar
                                       </button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Login',
   data: function() {
		return {
			usuario: '',
         senha: '',
         manterConectado: false
		}
	},
   methods: {
		verificarLogin : function () {
         if (this.usuario.length > 0 && this.senha.length > 0) {
            this.$store.dispatch('verificarCredenciais', {'usuario': this.usuario, 'senha': this.senha, 'manterConectado': this.manterConectado});
         }
		}
	}
}

</script>