<template>
   <div class="col-12 px-0">
      <div class="row">
         <div class="col">
            <label><i class="far fa-paperclip color-theme font-11 me-1"></i> {{ label }}</label>
         </div>
         <div class="w-max-content">
            <div class="form-check">
               <input class="form-check-input" type="checkbox" id="flexCheckDefault" :checked="recortar" v-model="recortar">
               <label class="form-check-label" for="flexCheckDefault">Recortar</label>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-sm px-0 mb-3">
            <div class="row">
               <div class="w-max-content" v-if="resultado != null">
                  <div class="results__finnaly"><img :src="resultado" @error="imageError" /></div>
               </div>
               <div class="col">
                  <div class="form-group file-area">
                     <input type="file" ref="upload" accept=".jpg,.jpeg,.png,.webp" @change="onFileChange($event)" @drop="onFileChange($event)" />
                     <div class="file-dummy">
                        <div class="default">Escolher imagem</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="px-0" v-if="imagemCropper != null" :class="widthCropper">
            <cropperBox :upload="imagemCropper" @set="setImagemCropped($event)" @reset="resetImagem" />
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import cropperBox from '@/components/CropperBox.vue'

export default {
   name: "UploadImagem",
   props: ["resultado", "label", "widthCropper"],
   data: function () {
      return {
         recortar: !window.matchMedia("(pointer: coarse)").matches,
         imagemCropper: null
      }
   },
   components: {
		cropperBox
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      onFileChange : function (e) {
         let files = e.target.files || e.dataTransfer.files;
			let reader = new FileReader(); // Transforma em dataBase64

         if (!files.length) {
				return
			} else if (files[0].size > 512000) {
				this.$toast.fire({
					icon: 'error',
					title: 'Arquivo superior a 500KB.'
				});

				return
			}

         reader.onload = (e) => {
            if (this.recortar) {
               this.imagemCropper = e.target.result
            } else {
               this.setImagemCropped(e.target.result)
            }
         }

         reader.readAsDataURL(files[0]);
      },
      setImagemCropped : function (base64) {
         this.$emit('set', base64)
         this.resetImagem()
      },
      resetImagem : function () {
         this.imagemCropper = null;
         this.$refs.upload.value = null;
      }
   },
   mounted() {
      $(window).resize(() => {
         try {
            this.recortar = !window.matchMedia("(pointer: coarse)").matches
         } catch (error) {
            this.recortar = false
            console.log(error)
         }
      });
   }
}

</script>

<style scoped>

.form-check {
	margin-bottom: 4px;
	min-height: unset;
}

.form-check-input {
	cursor: pointer;
	border-radius: 4px !important;	
	transform: translateY(-2px);
}

.form-check-label {
	cursor: pointer;
	font-weight: 500;
	text-transform: uppercase;
	display: block;
	font-size: 11.5px;
	user-select: none;
}

@media (max-width: 768px) {
   .form-check {
      display: none;
   }
}

</style>