<template>
	<div id="app" v-if="isLogado">
		<div class="wrapper">
			<div class="body-loading" v-if="isCarregando == true">
				<span class="loader"></span>
			</div>

			<!-- Menu NavBar -->
			<NavBar @abrirEditarPerfil="abrirEditarPerfil"/>

			<!-- modalVerUsuario -->
			<div class="modal fade" id="modalVerUsuario" tabindex="-1" aria-labelledby="modalVerUsuarioLabel" aria-hidden="true">
				<div class="modal-dialog modal-lg h-100 modal-dialog-centered modal-dialog-scrollable">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalVerUsuarioLabel">Editar perfil</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="card mb-2">
								<div class="card-body p-12">
									<uploadImagem :resultado="modal.banner" label="Banner" widthCropper="col-md-6" @set="addImagem($event, 'Principal')" />
									<div class="row">
										<div class="col-6 mb-3">
											<label class="mb-1"><i class="far fa-lock color-theme me-2 font-12"></i>Senha</label>
											<input type="password" class="form-control font-14" placeholder="Digite sua senha" v-model="modal.senha">
										</div>
										<div class="col-6 mb-3">
											<label class="mb-1"><i class="far fa-lock color-theme me-2 font-12"></i>Confirmar senha</label>
											<input type="password" class="form-control font-14" placeholder="Confirme sua senha" v-model="modal.confirmarSenha">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" @click="salvarPerfil">Salvar</button>
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
						</div>
					</div>
				</div>
			</div>
			
			<!-- Corpo sistema -->
			<div class="page-wrapper">
				<div class="page-content">
					<ul class="nav nav-header">
						<li class="nav-item" v-for="(tab, index) in tabs" :key="index">
							<router-link class="nav-link" :to='tab.path +"?"+ JSON.stringify(tab.query).replace(/[\{\}]|["]/g, "").replace(/[:]/g, "=").replace(/[,]/g, "&")'
								:class="[tab.path == $route.path ? 'active' : '', index == 0 ? 'pe-3' : '']">
								<span>
									<i :class="tab.icon"></i>{{ tab.nome.replace(/_/g, " ").replace(/-/g, "/") }}
									<span v-if="tab.nome == 'Nota'"><small> | </small>{{ tab.query.chaveNota }}</span>
									<span v-else-if="tab.nome == 'Entrada_de_mercadorias'"><small> | </small>{{ tab.query.chaveNota }}</span>
									<span v-else-if="tab.nome == 'Conta'"><small></small>{{ '#' + tab.query.idConta }}</span>
								</span>
							</router-link>
							<i class="fal fa-times" @click="deleteTabData(index)" v-if="index != 0"></i>
						</li>
					</ul>

					<router-view />
				</div>

				<a href="javaScript:;" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
			</div>

			<div class="overlay toggle-icon"></div>
			
			<footer class="page-footer">
				<span class="ps-2 float-start d-none d-md-block">Copyright © 2024. Todos direitos reservados</span>
				<span class="pe-2 float-end">OneWay Systems</span>
			</footer>

			<!-- Mudar tema -->
         <div class="switcher-wrapper">
            <div class="switcher-btn"><i class='bx bx-cog bx-spin'></i></div>
            <div class="switcher-body">
               <div class="d-flex align-items-center">
                  <h5 class="mb-0 text-uppercase">Tema</h5>
                  <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
               </div>
               <hr/>
               <h6 class="mb-0"><i class="far fa-palette color-theme font-13 me-1"></i> Estilo</h6>
               <hr/>
               <div class="row row-cols-2 m-0 align-items-center">
                  <div class="form-check pe-1">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="lightmode" :checked="theme == 'light-theme'">
                     <label class="form-check-label ps-1" for="lightmode">Claro</label>
                  </div>
                  <div class="form-check pe-1">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="darkmode" :checked="theme == 'dark-theme'">
                     <label class="form-check-label ps-1" for="darkmode">Escuro</label>
                  </div>
               </div>
               <div class="row row-cols-2 m-0 align-items-center">
                  <div class="form-check pe-1">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="semidark" :checked="theme == 'semi-dark'">
                     <label class="form-check-label ps-1" for="semidark">Semi Escuro</label>
                  </div>
						<div class="form-check pe-1">
							<input class="form-check-input" type="radio" id="minimaltheme" name="flexRadioDefault" :checked="theme == 'minimal-theme'">
							<label class="form-check-label ps-1" for="minimaltheme">Minimal</label>
						</div>
               </div>
               <hr/>
               <h6 class="mb-0"><i class="far fa-paint-brush color-theme font-13 me-1"></i> Cor</h6>
               <hr/>
               <div class="header-colors-indigators">
                  <div class="row row-cols-auto g-3">
                     <div class="col">
                        <div class="indigator headercolor1" id="headercolor1"></div>
                     </div>
                     <!-- <div class="col">
                        <div class="indigator headercolor2" id="headercolor2"></div>
                     </div> -->
                     <div class="col">
                        <div class="indigator headercolor3" id="headercolor3"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor4" id="headercolor4"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor5" id="headercolor5"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor6" id="headercolor6"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor7" id="headercolor7"></div>
                     </div>
                     <div class="col">
                        <div class="indigator headercolor8" id="headercolor8"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
		</div>
	</div>

	<div id="app" class="logout bg-login" v-else>
		<div class="body-loading" v-if="isCarregando == true">
			<span class="loader"></span>
		</div>

		<Login />
	</div>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import Login from '@/views/Login.vue'
import NavBar from '@/components/MenuNavBar.vue'
import uploadImagem from '@/components/UploadImagem.vue'

export default {
	name: 'App',
	data: function() {
		return {
         themeColor: (localStorage.carolinaERP_color == null ? 'color-header headercolor1' : 'color-header '+ localStorage.carolinaERP_color),
         theme: (localStorage.carolinaERP_theme == null ? 'light-theme' : localStorage.carolinaERP_theme),
			modal: {'id': null, 'senha': null, 'confirmarSenha': null, 'banner': null, 'fotos':[]},
			mostrarSenha: false,
			mostrarSenha2: false
		}
	},
	computed: {
		... mapState({
			isCarregando: state => state.isCarregando,
			isLogado: state => state.isLogado,
			tabs: state => state.tabs,
			urlRest: state => state.urlRest,
			dadosUsuario: state => state.dadosUsuario
		})
	},
	watch: {
		isLogado : function () {
			this.configJs()
		},
	},
	components: {
		NavBar, Login, uploadImagem
	},
	methods: {
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		deleteTabData : function (index) {
			this.$store.dispatch('deleteTabData', index)
		},
		configJs : function () {
			Vue.nextTick(function() {
				$(window).on("scroll", function () {
					if ($(this).scrollTop() > 300) {
						$('.back-to-top').fadeIn();
					} else {
						$('.back-to-top').fadeOut();
					}
				});
		
				$('.back-to-top').on("click", function () {
					$("html, body").animate({
						scrollTop: 0
					}, 600);
					return false;
				});
		
				$(".switcher-btn").on("click", function () {
					$(".switcher-wrapper").toggleClass("switcher-toggled");
				});
				$(".close-switcher").on("click", function () {
					$(".switcher-wrapper").removeClass("switcher-toggled");
				});
				$("#lightmode").on("click", function () {
					localStorage.carolinaERP_theme = 'light-theme';
					$('html').addClass('light-theme');
					$('html').removeClass('dark-theme semi-dark minimal-theme');
				});
				$("#darkmode").on("click", function () {
					localStorage.carolinaERP_theme = 'dark-theme';
					$('html').addClass('dark-theme');
					$('html').removeClass('light-theme semi-dark minimal-theme');
				});
				$("#semidark").on("click", function () {
					localStorage.carolinaERP_theme = 'semi-dark';
					$('html').addClass('semi-dark');
					$('html').removeClass('light-theme dark-theme minimal-theme');
				});
				$("#minimaltheme").on("click", function () {
					localStorage.carolinaERP_theme = 'minimal-theme';
					$('html').addClass('minimal-theme');
					$('html').removeClass('light-theme dark-theme semi-dark');
				});
				$("#headercolor1").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor1';
					$("html").addClass("color-header headercolor1");
					$("html").removeClass("headercolor2 headercolor3 headercolor4 headercolor5 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor2").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor2';
					$("html").addClass("color-header headercolor2");
					$("html").removeClass("headercolor1 headercolor3 headercolor4 headercolor5 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor3").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor3';
					$("html").addClass("color-header headercolor3");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor4").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor4';
					$("html").addClass("color-header headercolor4");
					$("html").removeClass("headercolor1 headercolor2 headercolor3 headercolor5 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor5").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor5';
					$("html").addClass("color-header headercolor5");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor3 headercolor6 headercolor7 headercolor8");
				});
				$("#headercolor6").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor6';
					$("html").addClass("color-header headercolor6");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor3 headercolor7 headercolor8");
				});
				$("#headercolor7").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor7';
					$("html").addClass("color-header headercolor7");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor6 headercolor3 headercolor8");
				});
				$("#headercolor8").on("click", function () {
					localStorage.carolinaERP_color = 'headercolor8';
					$("html").addClass("color-header headercolor8");
					$("html").removeClass("headercolor1 headercolor2 headercolor4 headercolor5 headercolor6 headercolor7 headercolor3");
				});
			}.bind(this));
		}, 
		abrirEditarPerfil : function () {
			this.modal = {'id': null, 'senha': null, 'confirmarSenha': null, 'banner': null}

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getPerfil',
				params: {
					id: this.dadosUsuario.usuarioId
				}

			}).then(response => {
				this.modal = {'id': this.dadosUsuario.usuarioId, 'senha': response.data.senha, 'confirmarSenha': response.data.senha, 'banner': response.data.banner}

				$('#modalVerUsuario').modal('show')

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarPerfil : function () {
			this.$store.dispatch('base64toFile', Array.of(this.modal.banner)).then((banner) => {
	
				this.$store.dispatch('alternarTelaCarregamento', true)
	
				let dataForm = new FormData();
				dataForm.append('info', JSON.stringify(this.modal));

				if (banner[0] instanceof File) {
					dataForm.append('banner', banner[0], String(banner[0].name).replace(/[^\w\s.]/gi, ''));
				}
	
				this.$axios({
					method: 'post',
					url: this.urlRest +'configuracoesAdmin/savePerfil',
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					data: dataForm
	
				}).then(() => {
					$('#modalVerUsuario').modal('hide')
					
					this.$toast.fire({
						icon: 'success',
						title: 'Produto salvo!'
					});
				}).catch((error) => {
					if (error.response != undefined) {
						if (error.response.status == 408) {
							this.$store.dispatch('deslogar')
	
							this.$toast.fire({
								icon: 'warning',
								title: 'Sessão expirada!'
							});
						} else {
							this.$toast.fire({
								icon: 'error',
								title: 'Erro: ' + error.response.status
							});
						}
					} else {
						this.$toast.fire({
							icon: 'error',
							title: error
						});
					}
				}).finally(() => {
					this.$store.dispatch('alternarTelaCarregamento', false)
				});
			})
		},
		addImagem : function (img, local) {
			switch (local) {
				case 'Principal':
					this.modal.banner = img;
				break;
				case 'Complementar':
					this.modal.fotos.push(img);
				break;
			}
      },
	},
	mounted() {
		$("html").attr("class", this.theme +' '+ this.themeColor)
		this.$store.dispatch('verificarCredenciais')
	}
}

</script>

<style scoped>
.password-input {
   position: relative !important;
}

.password-field {
   padding-right: 30px !important;
}
 
.toggle-password {
   position: absolute !important;
   top: 73% !important;
   transform: translateY(-50%) !important;
   right: 15px !important;
   cursor: pointer !important;
}
</style>